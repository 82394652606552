import faker from 'faker'
import { keys, random, range, sample, upperFirst } from 'lodash'

import { bind } from '../common/utils'
import { getPageNumber } from './'

export const directions = {
  MO: 'Received',
  MT: 'Sent',
}

export const routingSourceTypes = {
  SRC_HTTP: 'HTTP',
  SRC_SMPP: 'SMPP',
  SRC_SMS: 'SMS',
  SRC_EMAIL: 'EMAIL',
  SRC_OPENHTTP: 'OPENHTTP',
  SRC_APPLICATION: 'APPLICATION',
}

export const routingDestinationTypes = {
  DST_SMS: 'SMS',
  DST_RCS: 'RCS',
  DST_EMAIL: 'EMAIL',
  DST_HTTP: 'HTTP',
  DST_SMPP: 'SMPP',
  DST_OPENHTTP: 'OPENHTTP',
  DST_HTTP_PULL: 'HTTP (pull)',
  DST_HTTP_INT: 'INTEGRATION',
  DST_APPLICATION: 'APPLICATION',
}

/**
 * Route pairs
 */

export const routingPairs = {
  SRC_HTTP: ['DST_SMS', 'DST_RCS'],
  SRC_SMPP: ['DST_SMS'],
  SRC_SMS: [
    'DST_HTTP',
    'DST_SMPP',
    'DST_HTTP_PULL',
    'DST_SMS',
    'DST_EMAIL',
    'DST_OPENHTTP',
  ],
  SRC_EMAIL: ['DST_SMS'],
  SRC_OPENHTTP: ['DST_SMS'],
}

/**
 * DR types
 */
export const routingMethods = {
  JSON: 'JSON',
  POST: 'POST',
  GET: 'GET',
}

/**
 * Router API
 */
export class Router {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteRouting',
      'deleteRoutingRule',
      'getResources',
      'getApiKey',
      'getRouting',
      'getRoutings',
      'getRoutingRule',
      'getRoutingRules',
      'getConnectionSettings',
      'getSettings',
      'saveRouting',
      'saveRoutingRule',
      'saveSettings',
      'getOpenHttpInfo',
      'getOpenHttpParams',
    ])
  }

  /**
   * Delete routing
   */
  deleteRouting({ id, customerId }) {
    return this.api
      .delete('service/messagerouter/routerules/' + id, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  /**
   * Delete routing rule
   */
  deleteRoutingRule({ id }) {
    return this.api.delete('mgmt/routingrules/' + id).then(() => {
      return {
        success: true,
      }
    })
  }

  /**
   * Get mock routings
   */
  getMockRoutings() {
    if (!this.routings) {
      this.routings = [
        ...range(50).map((i) => {
          const source = {
            type: sample(keys(routingPairs)),
            key: '',
            url: '',
            method: '',
            number: '',
            keyword: '',
            senders: '',
            domains: '',
          }
          const destination = {
            type: sample(routingPairs[source.type]),
            key: '',
            url: '',
            method: '',
            sender: '',
            recipients: [],
            cc: '',
            bcc: '',
            subject: '',
            message: '',
          }
          switch (source.type) {
            case 'http':
              source.key = faker.random.uuid()
              source.url = faker.internet.url()
              source.method = sample(keys(routingMethods))
              break
            case 'sms':
              source.number = faker.phone.phoneNumber()
              source.keyword = faker.lorem.word()
              source.senders = range(random(1, 5)).map(() =>
                faker.phone.phoneNumber()
              )
              break
            case 'email':
              source.key = faker.random.uuid()
              source.domains = range(random(1, 5)).map(
                () => '*@' + faker.internet.domainName()
              )
              break
            default:
              break
          }
          switch (destination.type) {
            case 'http':
              destination.url = faker.internet.url()
              destination.method = sample(keys(routingMethods))
              break
            case 'httpPull':
              destination.key = faker.random.uuid()
              break
            case 'sms':
              destination.sender = faker.phone.phoneNumber()
              destination.recipients = range(random(1, 5)).map(() =>
                faker.phone.phoneNumber()
              )
              destination.message = faker.lorem.sentences(random(1, 5))
              break
            case 'email':
              destination.sender = 'support@nextup.fi'
              destination.recipients = [faker.internet.email()]
              destination.cc = sample([true, false])
                ? [faker.internet.email()]
                : []
              destination.bcc = sample([true, false])
                ? [faker.internet.email()]
                : []
              destination.subject = faker.lorem.words(random(3, 6))
              destination.message = faker.lorem.sentences(random(1, 5))
              break
            default:
              break
          }
          return {
            id: i + 1 + '',
            source,
            destination,
            billingReference: faker.random.uuid(),
            description: faker.lorem.sentences(random(1, 2)),
            created: faker.date.recent(90),
          }
        }),
      ]
      this.lastRoutingId = this.routings.length
    }
    return this.routings
  }

  /**
   * Get mock routing rules
   */
  getMockRoutingRules() {
    if (!this.routingRules) {
      this.routingRules = [
        ...range(100).map((i) => {
          return {
            id: i + 1 + '',
            name: upperFirst(faker.lorem.words(random(2, 5))),
            priority: random(0, 1000),
            direction: sample(keys(directions)),
            matchers: range(random(1, 3))
              .map(() => {
                return upperFirst(faker.lorem.words(random(1, 3)))
              })
              .join('\n'),
            actions: range(random(1, 10))
              .map(() => {
                return upperFirst(faker.lorem.words(random(1, 5)))
              })
              .join('\n'),
            quickrule: false,
            created: faker.date.recent(90),
          }
        }),
      ]
      this.lastRoutingRuleId = this.routingRules.length
    }
    return this.routingRules
  }

  /**
   * Get mock settings
   */
  getMockSettings() {
    if (!this.settings) {
      this.settings = {
        sender: '',
        ipAddresses: '',
        phoneNumbers: '',
      }
    }
    return this.settings
  }

  /**
   * Resources
   */
  getResources({ customerId }) {
    return this.api
      .get(`service/messagerouter/resources`, {}, true, {
        'X-Customer': customerId,
      })
      .then((result) => {
        return {
          httpCommonApi: result.info_http_common_api_url,
          httpApiDocs: result.info_http_api_docs_url,
          smsApi: result.info_docs_url,
          info_docs_zapier_url: result.info_docs_zapier_url,
          groupsApi: result.info_groupsapi_url,
          appApi: result.info_appapi_url,
          smsHttpApi: result.int_smsmtapi_url,
          rcsHttpApi: result.int_rcs_mt_api_url,
          smsEmailApi: result.int_emailapi_address,
          smsHttpPullApi: result.int_smsmopullapi_url,
          firewallsIps: result.int_ipaddress_info.split('\n'),
          infoPricingUrl: result.info_pricing_url,
          urlsWithAuth: result.urls_with_auth,
          v2_api_url: result.v2_api_url,
        }
      })
  }

  /**
   * Get routing apiKey
   */
  getApiKey({ customerId }) {
    return this.api.get(`service/messagerouter/apikey`, {}, true, {
      'X-Customer': customerId,
    })
  }

  /**
   * Get single routing by ID
   */
  getRouting({ id, customerId }) {
    return this.api.get('service/messagerouter/routerules/' + id, {}, true, {
      'X-Customer': customerId,
    })
  }

  /**
   * Get routings
   */
  getRoutings(options = {}) {
    if (options.search?.['routesrc.type'] === 'SRC_HTTP') {
      options.search['routesrc.type'] = undefined
      options.search['routesrc.type__in'] = ['SRC_HTTP', 'SRC_HTTP2RCS']
    }

    return this.api
      .get(
        'service/messagerouter/routerules?page=' + getPageNumber(options),
        {},
        true,
        {
          'X-Customer': options.extra.customerId,
          ...this.api.withFilters(options, {
            'routesrc.type': 'routesrc__src',
            'routesrc.type__in': 'routesrc__src__in',
            routesrc: 'routesrc__src',
            'routedst.type': 'routedst__dst',
            routedst: 'routedst__dst',
            billingref: 'billingref',
            description: 'description',
            created: 'created',
          }),
        }
      )
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  /**
   * Get single routing rule by ID
   */
  getRoutingRule({ id }) {
    return this.api.get('mgmt/routingrules/' + id).then((result) => {
      return {
        id: result.id + '',
        name: result.name,
        priority: result.priority,
        direction: result.direction,
        matchers: result.matchers,
        actions: result.actions,
        quickrule: result.quickrule,
        created: new Date(),
      }
    })
  }

  /**
   * Get routing rules
   */
  getRoutingRules(options = {}) {
    return this.api
      .get(
        'mgmt/routingrules?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          name: 'name',
          priority: 'priority',
          direction: 'direction',
          matchers: 'matchers',
          actions: 'actions',
          quickrule: 'quickrule',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              name: result.name,
              priority: result.priority,
              direction: result.direction,
              matchers: result.matchers,
              actions: result.actions,
              quickrule: result.quickrule,
            }
          }),
        }
      })
  }

  /**
   * Get settings
   */
  getSettings({ customerId }) {
    return this.api
      .get(`service/messagerouter/settings`, {}, true, {
        'X-Customer': customerId,
      })
      .then((result) => {
        return {
          sender: result.sender,
          ipRestrictions: result.iprestrictions || '',
          whitelistedNumbers: result.whitelisted_numbers || '',
        }
      })
  }

  /**
   * Save routing
   */
  async saveRouting(routing) {
    if (routing.id) {
      return await this.api.put(
        'service/messagerouter/routerules/' + routing.id,
        {
          ...routing,
        },
        true,
        {
          'X-Customer': routing.customerId,
        }
      )
    } else {
      return await this.api.post(
        'service/messagerouter/routerules',
        {
          ...routing,
        },
        true,
        {
          'X-Customer': routing.customerId,
        }
      )
    }
  }

  /**
   * Save routing rule
   */
  async saveRoutingRule(routingRule) {
    if (routingRule.id) {
      return await this.api.put('mgmt/routingrules/' + routingRule.id, {
        id: parseInt(routingRule.id),
        actions: routingRule.actions,
        name: routingRule.name,
        priority: parseInt(routingRule.priority),
        direction: routingRule.direction,
        matchers: routingRule.matchers,
      })
    } else {
      return await this.api.post('mgmt/routingrules', {
        id: 0,
        actions: routingRule.actions,
        name: routingRule.name,
        priority: parseInt(routingRule.priority),
        direction: routingRule.direction,
        matchers: routingRule.matchers,
      })
    }
  }

  /**
   * Save settings
   */
  async saveSettings(settings) {
    return await this.api.put(
      `service/messagerouter/settings`,
      {
        sender: settings.sender,
        iprestrictions: settings.ipRestrictions,
        whitelisted_numbers: settings.whitelistedNumbers,
      },
      true,
      {
        'X-Customer': settings.customerId,
      }
    )
  }

  getConnectionSettings({ customerId, ctype, route }) {
    return this.api.get(`service/messagerouter/connectionsettings`, {}, true, {
      'X-Customer': customerId,
      'X-filter': JSON.stringify({
        ctype,
        route,
      }),
    })
  }

  getOpenHttpInfo({ customerId }) {
    return this.api.get(`service/messagerouter/openhttp/authinfo`, {}, true, {
      'X-Customer': customerId,
    })
  }

  getOpenHttpParams({ customerId }) {
    return this.api.get(`service/messagerouter/openhttp/params`, {}, true, {
      'X-Customer': customerId,
    })
  }
}
